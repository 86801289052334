<template>
  <v-navigation-drawer
    v-model="Sidebar_drawer"
    :color="SidebarColor"
    mobile-break-point="960"
    clipped
    :right="$vuetify.rtl"
    mini-variant-width="70"
    :expand-on-hover="expandOnHover"
    app
    id="main-sidebar"
  >
    <v-list dense nav>
      <!---USer Area -->
      <v-list-item two-line class="px-0">
        <v-list-item-avatar>
          <img
            :src="
              userData != null && userData.imageUrl != null
                ? `${userData.imageUrl}`
                : 'https://toppng.com/uploads/preview/avatar-png-115540218987bthtxfhls.png'
            "
          />
        </v-list-item-avatar>

        <v-list-item-content
          v-if="
            userData != null &&
              (userData.firstName != null || userData.lastName != null)
          "
        >
          <v-list-item-title
            >{{ userData.firstName }} {{ userData.lastName }}</v-list-item-title
          >
          <v-list-item-subtitle class="caption">{{
            userData.position
          }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <!---USer Area -->
      <!---Sidebar Items -->
      <v-list-item
        v-for="item in items"
        :key="item.title"
        :to="item.to"
        :active-class="`primary white--text`"
        link
        v-show="showMenuByRole(item.role)"
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!---Sidebar Items -->
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "Sidebar",
  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    items: [
      {
        title: "Hesab məlumatları",
        icon: "mdi-account-circle",
        to: "/pages/profile",
        role: [],
      },
      {
        title: "İstifadəçilər",
        icon: "mdi-account-multiple",
        to: "/users",
        role: ["Admin"],
      },
      {
        title: "Əməkdaşlar",
        icon: "mdi-account-box-multiple",
        to: "/employees",
        role: ["HR"],
      },
      {
        title: "Rollar",
        icon: "mdi-account-lock",
        to: "/roles",
        role: ["Role:list", "Role:add", "Admin"],
      },
      {
        title: "Hesablar",
        icon: "mdi-receipt-text-check",
        to: "/invoices",
        role: [
          "Invoice:list",
          "Accountant",
          "StaticData",
          "Invoice:add",
          "Admin",
        ],
      },
      {
        title: "Arabağlantılar",
        icon: "mdi-connection",
        to: "/interconnections",
        role: ["Interconnection:add", "Interconnection:list", "Admin"],
      },
      {
        title: "Antenlər",
        icon: "mdi-antenna",
        to: "/antennas",
        role: ["Antenna:add", "Antenna:list", "Admin"],
      },
      {
        title: "ATS-Uplink portlar",
        icon: "mdi-ethernet",
        to: "/ports",
        role: ["Port:add", "Port:list", "Admin"],
      },
      {
        title: "BRX və AİK-lər",
        icon: "mdi-file-link",
        to: "/brx-and-aiks",
        role: ["BrxAndAik:add", "BrxAndAik:list", "Admin"],
      },
      {
        title: "Kabellər",
        icon: "mdi-cable-data",
        to: "/cables",
        role: ["Cable:add", "Cable:list", "Admin"],
      },
      {
        title: "Korp. xidmət nöqtələri",
        icon: "mdi-printer-pos",
        to: "/services-points",
        role: ["ServicesPoint:add", "ServicesPoint:list", "Admin"],
      },
      {
        title: "Data kabinlər",
        icon: "mdi-server",
        to: "/data-cabins",
        role: ["DataCabin:add", "DataCabin:list", "Admin"],
      },
      {
        title: "Liflər",
        icon: "mdi-vector-link",
        to: "/lifs",
        role: ["Lif:add", "Lif:list", "Admin"],
      },
      {
        title: "Ölçülər",
        icon: "mdi-ruler",
        to: "/sizes",
        role: ["Size", "Admin"],
      },

      {
        title: "Anbar",
        icon: "mdi-warehouse",
        to: "/products",
        role: ["Product:list", "Product:add", "Admin"],
      },

      {
        title: "Alış-satış",
        icon: "mdi-order-alphabetical-ascending",
        to: "/product-orders",
        role: ["ProductOrder:list", "ProductOrder:add", "Admin"],
      },
    ],
  }),
  computed: {
    ...mapState(["SidebarColor", "SidebarBg"]),
    Sidebar_drawer: {
      get() {
        return this.$store.state.Sidebar_drawer;
      },
      set(val) {
        this.$store.commit("SET_SIDEBAR_DRAWER", val);
      },
    },
    ...mapGetters({
      userData: "getUserData",
    }),
  },
  watch: {
    "$vuetify.breakpoint.smAndDown"(val) {
      this.$emit("update:expandOnHover", !val);
    },
  },
  methods: {
    showMenuByRole(role) {
      if (role && role.length == 0) return true;
      if (this.userData != null && role.length > 0) {
        var roles = this.userData.roles;
        if (role.filter((element) => roles.includes(element)).length > 0)
          return true;
        else return false;
      }
    },
  },
  created() {
    this.showMenuByRole();
  },
};
</script>
<style lang="scss">
#main-sidebar {
  box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);
  .v-navigation-drawer__border {
    display: none;
  }
  .v-list {
    padding: 8px 15px;
  }
  .v-list-item {
    &__icon--text,
    &__icon:first-child {
      justify-content: center;
      text-align: center;
      width: 20px;
    }
  }
}
</style>
